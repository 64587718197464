import React from 'react';
import withGlobalProps from '@/Framework/Router/Next/withGlobalProps';
import LandingLayout from '@/evercall/ui/common/Layout/LandingLayout';
import Home from '@/evercall/ui/components/Home';

import layoutStyles from '@/evercall/ui/common/Layout/layout.scss';

export const getServerSideProps = withGlobalProps(() => ({ props: {} }));

export default (): React.ReactNode => (
  <LandingLayout containerCls={ layoutStyles.landingHomeContainer }>
    <Home />
  </LandingLayout>
);
